<template>
<!--  <div v-if="advise && list" @click="jumpRed">-->
<!--    <img style="position: fixed;left: 0;top: 54%;z-index: 99;width: 20%;" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/6f4a3f765a1b41adffec6aa4fd30871d.png" alt="">-->
<!--  </div>-->
  <banner :list="list"></banner>
  <ul class="nav-wapper">
    <li>
      <router-link
        :to="{
          name: 'ProductCategory',
        }"
      >
        <div>
          <img src="@/assets/img/leftNav/icon16.png" alt="">
        </div>
        Products
      </router-link>
    </li>
    <li>
      <router-link
              :to="{
          name: 'customize',
        }"
      >
        <div>
          <img style="width: 30px; height: 30px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/m-home-dz20201027.png" alt=""/>
        </div>
        Customized
      </router-link>
    </li>

<!--    <li>-->
<!--      <router-link :to="{ name: 'News' }">-->
<!--        <div>-->
<!--          <img style="width: 26.512px; height: 27.488px" src="@/assets/img/leftNav/icon12.png" alt=""/>-->
<!--        </div>-->
<!--        News-->
<!--      </router-link>-->
<!--    </li>-->
    <li>
      <router-link :to="{ name: 'About' }">
        <div>
          <svg
                  style="display: block; margin: 0 auto"
                  t="1612256353934"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="1975"
                  width="32"
                  height="32"
          >
            <path
                    d="M409.38666667 570.02666667c-72.42666667 0-131.30666667-58.88-131.30666667-131.30666667 0-72.42666667 58.88-131.30666667 131.30666667-131.30666667 72.42666667 0 131.30666667 58.88 131.30666666 131.30666667 0 72.42666667-58.98666667 131.30666667-131.30666666 131.30666667z m0-219.94666667c-48.85333333 0-88.64 39.78666667-88.64 88.64s39.78666667 88.64 88.64 88.64 88.64-39.78666667 88.64-88.64-39.78666667-88.64-88.64-88.64zM833.28 454.29333333H643.73333333c-11.73333333 0-21.33333333-9.6-21.33333333-21.33333333s9.6-21.33333333 21.33333333-21.33333333h189.54666667c11.73333333 0 21.33333333 9.6 21.33333333 21.33333333s-9.6 21.33333333-21.33333333 21.33333333zM833.28 556.37333333H643.84c-11.73333333 0-21.33333333-9.6-21.33333333-21.33333333s9.6-21.33333333 21.33333333-21.33333333h189.54666667c11.73333333 0 21.33333333 9.6 21.33333333 21.33333333-0.10666667 11.73333333-9.70666667 21.33333333-21.44 21.33333333z"
                    p-id="1976"
                    fill="#df0024"
            ></path>
            <path
                    d="M726.72 685.22666667l-138.34666667 221.86666666c-6.18666667 9.92-19.41333333 13.01333333-29.44 6.82666667-9.92-6.18666667-13.01333333-19.41333333-6.82666666-29.44l138.34666666-221.86666667c6.18666667-9.92 19.41333333-13.01333333 29.44-6.82666666 10.02666667 6.18666667 13.01333333 19.41333333 6.82666667 29.44z"
                    p-id="1977"
                    fill="#df0024"
            ></path>
            <path
                    d="M909.01333333 94.29333333H114.45333333c-18.77333333 0-34.13333333 15.36-34.13333333 34.13333334v766.82666666c0 18.77333333 15.36 34.13333333 34.13333333 34.13333334h71.36v-42.66666667h-62.82666666V136.96h777.49333333v749.76h-67.73333333v42.66666667h76.26666666c18.77333333 0 34.13333333-15.36 34.13333334-34.13333334V128.42666667c0-18.77333333-15.36-34.13333333-34.13333334-34.13333334z"
                    p-id="1978"
                    fill="#df0024"
            ></path>
            <path
                    d="M276.58666667 755.73333333c0-68.16 55.46666667-123.52 123.52-123.52h17.70666666c68.16 0 123.52 55.46666667 123.52 123.52l0.96 152c0.53333333 11.2 9.81333333 20.05333333 20.8 20.26666667 11.30666667 0.21333333 21.01333333-8.74666667 21.54666667-20.26666667-0.21333333-50.66666667-0.42666667-101.33333333-0.74666667-152 0-91.62666667-74.56-166.18666667-166.18666666-166.18666666H400c-91.62666667 0-166.18666667 74.56-166.18666667 166.18666666 0.10666667 50.66666667 0.32 101.33333333 0.42666667 152-0.21333333 11.52 9.06666667 21.22666667 20.58666667 21.33333334 11.52 0.10666667 21.01333333-9.28 21.12-20.8l0.64-152.53333334z"
                    p-id="1979"
                    fill="#df0024"
            ></path>
          </svg>
        </div>
        About
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'Contact' }">
        <div>
          <img style="width: 27.488px; height: 26.512px" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/m-home-weiye20201027.png" alt=""/>
        </div>
        Cooperation
      </router-link>
    </li>
  </ul>
  <div class="hot-wapper">
    <div class="hot-title">
      Introduction
    </div>
    <div class="video-wapper">
      <video
          id="player-container-id"
          src="https://1256414354.vod2.myqcloud.com/c8e6626avodtransgzp1256414354/ba91957b243791580683347934/v.f40.mp4"
          style="width: 100%;"
          playsinline
          webkit-playinline
          x5-playinline
          controls
          poster="https://weiyejiliang.oss-accelerate.aliyuncs.com/waimao/about_video_cover.jpg"
      ></video>
    </div>
  </div>
  <div class="qiyejieshao" style="width:100%;height: 275px">
    <ul class="ly_advantage_ul">

        <li>
            <div class="ly_advantage_left">
              <span>15,000 <span style="font-size: 12px;font-weight: 400">+</span> </span>
              <span>Reference material products</span>
            </div>
            <div class="ly_advantage_right">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/pro.png" />
            </div>
        </li>


      <li><div class="ly_advantage_left">
        <span>200 <spna style="font-size: 12px;font-weight: 400">+</spna></span>
        <span>Laboratory professional research workers</span>
      </div>
        <div class="ly_advantage_right">
          <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/home_5.png" />
        </div></li>
      <li>
        <div class="ly_advantage_left">
        <span>15<span style="font-size: 12px;font-weight: 400">years+</span></span>
        <span>Reference material sales experience</span>
      </div>
        <div class="ly_advantage_right">
          <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/home_index_1.png" />
        </div>
      </li>
      <li>
        <div class="ly_advantage_left">
          <span>90 <span style="font-size: 12px;font-weight: 400">%</span></span>
          <span>Warehouses all over the world with 90% stock in stock</span>
        </div>
        <div class="ly_advantage_right">
          <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/vue_img/home_4.png" />
        </div>
      </li>
    </ul>
  </div>
  <div class="hot-wapper module">
    <div class="hot-title">
      Classification
    </div>
    <div>
        <ul class="class_ul">

            <li class="class_li">
              <router-link  :to="{ name: 'product', query: { type: 0, id: 1 } }">
              <div class="module_left">
                <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/sp.jpg"/>
              </div>
              <div class="module_right">
                <p>Food Composition Analysis</p>
                <p>Reference materials for food composition analysis are mainly used in health and epidemic prevention, food testing departments.</p>
              </div>
              </router-link>
            </li>

          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 2 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/huanj.jpg"/>

            </div>
            <div class="module_right">
              <p>Environmental Chemical Analysis</p>
              <p>Suitable for daily analysis and testing in environmental chemical analysis, instrument calibration, analysis method confirmation and evaluation, measurement process quality control, technical arbitration and certification evaluation, etc.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 3 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/wul.jpg"/>

            </div>
            <div class="module_right">
              <p>Physical Property</p>
              <p>Suitable for the calibration of equipment in physical properties and physicochemical properties, evaluation of measurements, and the control of product quality.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 4 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/dz.jpg"/>

            </div>
            <div class="module_right">
              <p>Geological Mineral Composition Analysis</p>
              <p>Mainly used for quality evaluation of analytical methods, monitoring of analytical quality, and calibration of analytical instruments for relevant samples in geology, beneficiation and other departments.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 5 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/jianc.jpg"/>

            </div>
            <div class="module_right">
              <p>Building Materials</p>
              <p>Suitable for the calibration of equipment in physical properties and physicochemical properties, evaluation of measurements, and the control of product quality.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 6 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/huagong.jpg"/>

            </div>
            <div class="module_right">
              <p>Chemical Product Composition Analysis</p>
              <p>Derived from relevant components in chemical production, widely
                used for component identification, forensic identification of toxic and toxic components, etc.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 7 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/linc.jpg"/>

            </div>
            <div class="module_right">
              <p>Clinical Chemical and Drug Component Analysis</p>
              <p>Mainly includes physiologically related reference materials related to organisms.  Applications in identifying drugs, controlling drug quality, researching new drugs and chinese herbal medicines.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 8 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/meit.jpg"/>

            </div>
            <div class="module_right">
              <p>Coal and Petroleum Composition Analysis and Physical Property Measurement</p>
              <p>Applicable to coal and petroleum, about the transfer and unification of physical property parameters. Mainly used in the composition analysis of various coal and petroleum products.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 9 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/lid.jpg"/>

            </div>
            <div class="module_right">
              <p>Particle Size and Turbidity</p>
              <p>Mainly used for the calibration of equipment parameters measurement method evaluation and quality control.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 12 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/gt.jpg"/>

            </div>
            <div class="module_right">
              <p>Steel Composition Analysis</p>
              <p>Mainly used in steel composition analysis,  quality inspection, instrument calibration, measurement method evaluation, value transfer and quality control, etc.</p>
            </div>
            </router-link>
          </li>
          <li class="class_li">
            <router-link  :to="{ name: 'product', query: { type: 0, id: 13 } }">
            <div class="module_left">
              <img src="https://weiyejiliang.oss-cn-beijing.aliyuncs.com/waimao/index_product_img/yous.jpg"/>

            </div>
            <div class="module_right">
              <p>Analysis of Gas Composition in Metals</p>
              <p>It mainly includes heavy metals and alloys, high-purity substances and solution for volumetric analysis. Widely used in determination the relevant composition of materials and to identify the elemental composition of metals.
              </p>
            </div>
            </router-link>
          </li>
        </ul>
    </div>
  </div>


<!--  <menu-tab class="menu" :menu="menu" @change="selectMenu"></menu-tab>-->

<!--  <list url="/M/Server/GetLastNews" :show="currentMenuItem == 'News'">-->
<!--    <template v-slot:default="data">-->
<!--      <news-item :newData="data"></news-item>-->
<!--    </template>-->
<!--  </list>-->
<!--  <list
    url="/M/Product/getProductListByClassify"
    :filterFunc="productMap"
    :show="currentMenuItem == 'Hot sale'"
  >
    <template v-slot:default="data">
      <product-card :list="data" name="productdetail" tid="0"></product-card>
    </template>
  </list>-->


</template>
<!--<script>-->
<!--    (function() {-->
<!--        var _53code = document.createElement("script");-->
<!--        _53code.src = "https://tb.53kf.com/code/code/670c56f26e85ec66df9d068b5c2cd2531/1";-->
<!--        var s = document.getElementsByTagName("script")[0];-->
<!--        s.parentNode.insertBefore(_53code, s);-->
<!--    })();-->
<!--</script>-->
<script lang="ts">

import { defineComponent, onMounted, ref, nextTick, watch } from "vue";

import banner from "@/components/common/Swiper.vue";
// import list from "@/components/common/list.vue";

// import newsItem from "@/components/common/NewsItem.vue";
// import productCard from "@/components/common/productCard.vue";

// import MenuTab from "@/components/common/MenuTab.vue";
import Toast from "@/components/UI/Toast/";
import { productMap } from "@/hooks/useProduct";
import { useRouter, useRoute } from "vue-router";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import axios from "@/api/axios";

export default defineComponent({
  name: "Index",
  components: {
    banner,
    // list,
    // newsItem,
    // MenuTab,
    // productCard,
  },

  setup() {
    const advise = ref(true)
    const router = useRouter();
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 3,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });

    function filterFunc(data: any) {
      if (data.fenlei == 228) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }

    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
    });
    //  切换列表菜单
    type Menu = "Hot sale";
    const menu: Menu[] = [ "Hot sale"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    function jumpRed() {
      // advise.value = false
      // router.push({
      //   name: "WechatRed",
      // });
      const token = localStorage.getItem("token") || "";
      axios
        .get("/M/Event/redPacketWeChatUrlHtml", { headers: { token } })
        .then((res) => {
          if (res.data.success == false || res.data.msg == '登录失败') {
            Toast({
              title: 'login',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          } else {
            window.location.href = res.data.returnUrl;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    watch(
      () => {
        return list.value
      },
      () => {
        setTimeout(() => {
          advise.value = false
        }, 10000)
      }
    )

    return {
      list,
      hotSaleList,
      menu,
      selectMenu,
      currentMenuItem,
      productMap,
      filterFunc,
      jumpRed,
      advise
    };
  },
});
</script>

<style lang="scss" scoped>
//  nav

.nav-wapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 6px solid #f4f4f4;
  li {
    width: 25%;
    margin-top: 9px;
    float: left;
    height: 70px;
    img {
      width: 20px;
      height: 30px;
      display: block;
      margin: 0 auto;
    }
    a {
      display: block;
      width: 100%;
      height: 40px;
      text-align: center;
      font-size: 14px;
      color: #000;
    }
    div {
      height: 40px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }
  }
}

//  hot product

.hot-title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 15px;
  padding-left: 21px;
  box-sizing: border-box;
  position: relative;
  line-height: 48px;
  margin-bottom: 12px;
  color: #333;
  &::after {
    position: absolute;
    content: "";
    left: 10.5px;
    top: 50%;
    width: 2.5px;
    height: 13px;
    transform: translateY(-40%);
    background-color: #df0024;
  }
  .learn-more {
    position: absolute;
    right: 10.5px;
    top: 0;
    font-size: 14px;
    color: #666;
    img {
      vertical-align: middle;
      position: relative;
      top: -2px;
      width: 5px;
      height: 10px;
    }
  }
}
.hot-content {
  width: 100%;
  overflow: hidden;

  box-sizing: border-box;
  .recommend-container {
    padding-left: 9px;
  }
  .hot-container {
    width: 110px;
    display: block;
    img {
      width: 100%;
      //height: 110px;
      display: block;
      margin-bottom: 15px;
    }
    .hot-no {
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #f29773;
      font-size: 12px;
    }
    .hot-name {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 5px;
      color: #444444;
      font-size: 14px;
    }
  }
}
.menu-nav-wapper {
  margin-top: 20px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    border-top: 6px solid #f4f4f4;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}
.skeleton-wrapper {
  display: flex;
  justify-content: space-around;
}
.skeleton {
  .hot-container {
    .hot-img {
      width: 110px;
      height: 110px;
      display: block;
      margin-bottom: 8px;
    }
    .hot-no {
      width: 100%;
      height: 17px;
    }
    .hot-name {
      width: 100%;
      height: 20px;
    }
  }
  .skeleton-bg {
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 74.5%, 0.2) 25%,
      hsla(0, 0%, 50.6%, 0.24) 37%,
      hsla(0, 0%, 74.5%, 0.2) 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
  }
}

.post-header {
  height: 50px;
  display: block;
  line-height: 50px;
  border-bottom: 1px solid #f4f4f4;
}

.post-header .left {
  float: left;
  color: #666666;
  font-size: 16px;
  padding-left: 20px;
}

.post-header .post-header-right {
  float: right;
}

.post-header .post-header-right img {
  position: relative;

  width: 22px;
  height: 22px;
  border-radius: 50%;
}

.post-second-user {
  left: -5px;
}

.post-third-user {
  left: -15px;
}
.ly_advantage_ul{
  height: 100%;
  border-bottom: 0.16rem solid #f4f4f4;
}
.ly_advantage_ul li{
  width:48%;
  height: 100px;
  float:left;
  border:1px solid #ccc;
  margin-left: 5px;
  margin-top: 10px;
  color:#333;
}

.ly_advantage_left{
  width: 77%;
  float:left;
  margin-top: 5px;

}
.ly_advantage_right{
  width: 16%;
  float:right;
  margin-top:30px;
  margin-right: 12px;
}
.ly_advantage_right img{
  width:30px;
}

.ly_advantage_ul span{
  padding-left: 8px;
}
.ly_advantage_ul span:nth-child(1){
  font-size: 14px;

}
.ly_advantage_ul span:nth-child(2){
  font-size: 9px;
  display: block;
  margin-top: 10px;
}
.class_ul{
  width:100%;
}
.class_ul li{
  width:100%;
  min-height: 100px;
  padding: 10px ;
  color:#333;
  border-bottom: 0.02667rem solid #f4f4f4;
}


.module {
  margin-bottom: 80px;
}

.module_left{
  float:left;
  width: 78px;
  height: 78px;
  //border: .02667rem solid #ccc;
  text-align: center;
}

.module_left img{
  width: 100%;
}

.module_right{
  float:right;
  width: 75%;
}
.module_right p:nth-child(1){
  font-size: 14px;
  overflow: hidden;
  -webkit-line-clamp:1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color:#333;

}
.module_right p:nth-child(2){
  font-size: 10px;
  display: block;
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp:3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #333;


}
</style>
