

import { defineComponent, onMounted, ref, nextTick, watch } from "vue";

import banner from "@/components/common/Swiper.vue";
// import list from "@/components/common/list.vue";

// import newsItem from "@/components/common/NewsItem.vue";
// import productCard from "@/components/common/productCard.vue";

// import MenuTab from "@/components/common/MenuTab.vue";
import Toast from "@/components/UI/Toast/";
import { productMap } from "@/hooks/useProduct";
import { useRouter, useRoute } from "vue-router";
//  utils
import api from "@/api/axios";
import Swiper from "swiper";
import axios from "@/api/axios";

export default defineComponent({
  name: "Index",
  components: {
    banner,
    // list,
    // newsItem,
    // MenuTab,
    // productCard,
  },

  setup() {
    const advise = ref(true)
    const router = useRouter();
    onMounted(() => {
      nextTick(() => {
        const swiper = new Swiper("#hot-swiper", {
          slidesPerView: 3,
          initialSlide: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
        });
      });
    });

    function filterFunc(data: any) {
      if (data.fenlei == 228) {
        const teacherNames =
          (data.teacherNames && data.teacherNames.split(",")) || [];
        const teacherIds =
          (data.teacherIds && data.teacherIds.split(",")) || [];
        teacherNames.pop();
        teacherIds.pop();
        data.teacherNames = teacherNames;
        data.teacherIds = teacherIds;
      }
      return data;
    }

    const list = ref(null);
    const hotSaleList = ref(null);
    api.get("/M").then((res): void => {
      list.value = res.data.obj.listimg;
      hotSaleList.value = res.data.obj.hotSaleList;
    });
    //  切换列表菜单
    type Menu = "Hot sale";
    const menu: Menu[] = [ "Hot sale"];
    const currentMenuItem = ref(menu[0]);
    function selectMenu(item: Menu) {
      currentMenuItem.value = item;
    }
    function jumpRed() {
      // advise.value = false
      // router.push({
      //   name: "WechatRed",
      // });
      const token = localStorage.getItem("token") || "";
      axios
        .get("/M/Event/redPacketWeChatUrlHtml", { headers: { token } })
        .then((res) => {
          if (res.data.success == false || res.data.msg == '登录失败') {
            Toast({
              title: 'login',
              type: "error",
            });
            router.push({
              path: "/login"
            });
          } else {
            window.location.href = res.data.returnUrl;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    watch(
      () => {
        return list.value
      },
      () => {
        setTimeout(() => {
          advise.value = false
        }, 10000)
      }
    )

    return {
      list,
      hotSaleList,
      menu,
      selectMenu,
      currentMenuItem,
      productMap,
      filterFunc,
      jumpRed,
      advise
    };
  },
});
